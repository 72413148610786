import React, { useState } from 'react';
import { Col, Modal } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, detailedDescription, descImage }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={toggleModal}>
        <img src={imgUrl} alt="Project" />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>

      <Modal show={showModal} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'black' }}>
            {title}
            </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <Modal.Body style={{ color: 'black' }}>
          <p>{detailedDescription}</p>
          <img src={descImage} alt="Project Detail" style={{ maxWidth: '100%' }} />
        </Modal.Body>
      </Modal>
    </Col>
  );
};
//   return (
//     <Col size={12} sm={6} md={4}> 
//       <div className="proj-imgbx">
//         <img src={imgUrl} alt ="Project" />
//         <div className="proj-txtx">
//           <h4>{title}</h4>
//           <span>{description}</span>
//         </div>
//       </div>

//       <Modal show={showModal} onHide={toggleModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>{title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {detailedDescription}
//         </Modal.Body>
//       </Modal>
//     </Col>
//   )
// }
