import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/shareteainv.png";
import projImg2 from "../assets/img/gtwebdev.png";
import comingsoon from "../assets/img/comingsoon.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import shareteainv from "../assets/img/inventoryoutput.png";
import projectplatform from "../assets/img/projectplatform.png"
import basketballAI from "../assets/img/basketballAI.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Sharetea Inventory Optimizer",
      description: "Software Development and System Integration",
      imgUrl: projImg1,
      detailedDescription: "In my role at Sharetea, I implemented a Python and SQL-based inventory management system tailored for boba product logistics. This system streamlined the entire inventory process, from tracking stock levels to optimizing shipment schedules, enhancing our supply chain management and increasing our operational efficiency. Due to the proprietary nature of the system and to protect sensitive company information, the source code and detailed system specifics are not publicly available, but I have attached an image of how it works!",
      descImage: shareteainv,
    },
    {
      title: "Project Platform",
      description: "Design & Development",
      imgUrl: projImg2,
      detailedDescription: "Project platform is an advanced dashboard for Georgia Tech students, enhancing collaboration and project transparency through the integration of the Github API. My role encompassed full-stack development, employing technologies like Node, MongoDB, Express, and React to deliver a seamless front-end experience and reliable back-end operations. This dashboard allowed students to register their own accounts and showcase the projects they have worked on at GT WebDev. https://github.com/edisonylee/gt-webdev-project-platform",
      descImage: projectplatform,
    },
    {
      title: "AI Basketball Scorekeeper",
      description: "New project otw...",
      imgUrl: basketballAI,
      detailedDescription: "Currently working on a basketball analysis tool harnessing AWS DeepLens and Amazon Rekognition for real-time shot tracking and scoring. This innovative system, using AWS Lambda and DynamoDB, promises a revolutionary way to process and update game scores instantly. Stay tuned for a game-changing experience in basketball analytics! ",
      descImage: comingsoon,
    },
    // {
    //   title: "More coming soon!",
    //   description: "Anything",
    //   imgUrl: projImg1,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p> A collection of the projects I have worked on both collegiately, professionally, and personally! </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Projects</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>  
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
